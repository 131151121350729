import React, {
  createContext, useContext, useState, useEffect, ReactNode, useMemo,
} from 'react';
import { useRouter } from 'next/router';
import {
  IAddress, IMeQuery, IUser, Maybe,
} from '../../lib/graphql';

const UserContext = createContext(undefined);

type IUserProviderProps = {
  children: ReactNode
}

type ILoginUser = Pick<IUser, 'firstName' | 'lastName' | 'email' | 'profileImageUrl'>

type IMeUser = Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email' | 'profileImageUrl' | 'stripeCustomerId' | 'roles'>
& { address?: Maybe<(
  { __typename?: 'Address' }
  & Pick<IAddress, 'line1' | 'line2' | 'city' | 'county' | 'country' | 'postCode'>
)> }

const UserProvider = ({ children }:IUserProviderProps):any => {
  const { pathname } = useRouter();
  const [user, setUser] = useState<undefined|IMeUser|ILoginUser>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  async function login(email, password): Promise<boolean | undefined> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      try {
        // This is not an API route due to cookies not being updated
        // const apolloClient = initializeApollo('');
        // const response = await apolloClient.mutate({
        //   mutation: LoginDocument,
        //   variables: {
        //     data: {
        //       email,
        //       password,
        //     },
        //   },
        //   refetchQueries: [{ query: GetBasketDocument }],
        // });
        // const mutationResp: ILoginMutation = response.data;
        // const { cookie } = document;
        // console.log('COOOOOKIIIIIEEEE', cookie);
        // console.log(response);

        const response = await fetch('/api/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ email, password }),
        });

        const resp = await response.json();
        if (resp.graphQLErrors) {
          setErrors(resp.graphQLErrors);
          reject(resp.message);
        }
        await getUser();
        resolve(true);
      } catch (err: unknown) {
        setErrors([err]);
        reject(err);
      }
      setLoading(false);
    });
  }

  async function logout() {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      const profile = await response.json();
      if (profile.error) {
        setUser(null);
      } else {
        setUser(null);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  async function getUser() {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/me', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      const resp = await response.json();
      // eslint-disable-next-line prefer-destructuring
      const data: IMeQuery = resp.data;
      const profile = data.me?.user;
      if (profile) {
        setUser(profile);
      } else {
        setUser(null);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getUser();
  }, [pathname]);

  // This redirect people but not sure how I can use it yet

  // useEffect(() => {
  //   // Check that a new route is OK
  //   const handleRouteChange = (url) => {
  //     if (url !== '/' && !user) {
  //       window.location.href = '/';
  //     }
  //   };

  //   // Check that initial route is OK
  //   if (pathname !== '/' && user === null) {
  //     window.location.href = '/';
  //   }

  //   // Monitor routes
  //   events.on('routeChangeStart', handleRouteChange);
  //   return () => {
  //     events.off('routeChangeStart', handleRouteChange);
  //   };
  // }, [user]);

  const userProps = useMemo(() => ({
    user, loading, errors, login, logout,
  }), [user, loading, errors]);

  return (
    <UserContext.Provider value={userProps}>
      {children}
    </UserContext.Provider>
  );
};

type useAuthType = {
  user: undefined| IMeUser,
  loading: boolean,
  errors: Array<string>,
  login: (email: string, password: string) => Promise<IMeUser | unknown>,
  logout: () => void,
}

function useAuth(): useAuthType {
  return useContext(UserContext);
}

export { UserProvider, useAuth };
