import { MenuItem, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

interface NavMenuItemProps extends React.HTMLAttributes<HTMLAnchorElement>{
  label: string;
  url?: string;
  onClick?: () => void;
}
const NavMenuItem = ({ label, url, onClick }: NavMenuItemProps) => {
  const navStyle = useStyleConfig('Button', { variant: 'ghostHover', colorScheme: 'blue', size: 'sm' });

  return (
    <MenuItem sx={navStyle} as="a" href={url} onClick={onClick}>
      {label}
    </MenuItem>
  );
};

export default NavMenuItem;
