import { Flex } from '@chakra-ui/react';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

// eslint-disable-next-line react/prop-types
const MainLayout = ({ children }: Props): ReactElement => (
  <Flex style={{ minHeight: '100vh' }}>
    {children}
  </Flex>
);

export default MainLayout;
