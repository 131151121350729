import {
  Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton,
} from '@chakra-ui/react';
import {
  FC, ReactElement, useState, useEffect,
} from 'react';

const AlertItem: FC<IAlerterItem> = ({
  title, message, autoDismiss = false, type = 'info', closeToggle = true, key,
}: IAlerterItem): ReactElement => {
  const dismissTimer = 4500;
  const [isVisible, setIsVisible] = useState(true);
  let timer: NodeJS.Timer;

  function handleClose() {
    setIsVisible(false);
    clearTimeout(timer);
  }

  useEffect(() => {
    if (autoDismiss) {
      timer = setTimeout(handleClose, dismissTimer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    isVisible && (
      <Alert status={type} pr={4} key={key}>
        <AlertIcon />
        {title && <AlertTitle mr={2}>{title}</AlertTitle>}
        <AlertDescription pr={6}>{message}</AlertDescription>
        {closeToggle && <CloseButton position="absolute" right={2} top={2} />}
      </Alert>
    )
  );
};

export default AlertItem;
