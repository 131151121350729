import {
  Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import AuthForm from './authForm';

interface Props {
  onClose: () => void
  isOpen: boolean
}

const LoginModal = ({ onClose, isOpen } : Props) => (
  <Modal id="loginModal" onClose={onClose} isOpen={isOpen} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <AuthForm onSuccess={onClose} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default LoginModal;
