import React from 'react';
import {
  Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay,
  HStack, Icon, Stack, Text, useDisclosure,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import router from 'next/router';
import MyAccountNav from './NavMenus/MyAccount';
import ClassBooking from './NavMenus/ClassBooking';
import LoginButton from '../login/LoginButton';
import isMobile from '../../utils/isMobile';

const MobileNav = () => {
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();

  if (!isMobile()) return null;

  return (
    <>
      <Button onClick={onOpenMenu} colorScheme="yellow"><Icon as={FiMenu} /></Button>
      <Drawer
        id="leftMenu"
        isOpen={isOpenMenu}
        placement="right"
        onClose={onCloseMenu}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>
              <Box height={4}>
                <HStack>
                  <DrawerCloseButton />
                </HStack>
              </Box>
            </DrawerHeader>

            <DrawerBody>
              <Stack p={2} alignItems="flex-end">
                <Button onClick={(() => { onCloseMenu(); router.push('https://ukchinaperformingarts.com/about-ukcpa'); })} variant="link">About Us</Button>
                <Button onClick={(() => { onCloseMenu(); router.push('https://ukchinaperformingarts.com/contact-us'); })} variant="link">Contact Us</Button>

                <Text fontSize="xl">Account</Text>
                <MyAccountNav variant="buttons" />
                <hr />
                <Text fontSize="xl">Courses</Text>
                <ClassBooking variant="buttons" />
                {/* <EventBookingNav variant="button" /> */}
                <hr />
                <LoginButton />

              </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default MobileNav;
