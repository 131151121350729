import React, {
  createContext, ReactNode, useMemo,
} from 'react';
import envVariables from '../envVariables';

export type ContextType = {
  paths: {
    root: string,
    courses: string,
    events: string,
    adultCourses: string,
    childrenCourses: string,
  }
  isCourseSite: boolean,
  isEventsSite: boolean,
  isAdultSite: boolean,
  isChildSite: boolean
}

type INavProviderProps = {
  children: ReactNode
}

const NavigationContext = createContext<ContextType>(undefined);

const NavigationProvider = ({ children }: INavProviderProps): any => {
  const {
    SITE, SITE_ID, ADULTS_HOST, CHILD_HOST, EVENTS_HOST, LOCALHOST,
  } = envVariables;

  const isChildSite = SITE_ID === 'CATS';
  const isAdultSite = SITE_ID === 'UKCPA';
  const isEventsSite = SITE === 'EVENTS';
  const isCourseSite = isChildSite || isAdultSite;
  const siteHost = SITE_ID === 'CATS' ? CHILD_HOST : ADULTS_HOST;

  const siteProtocol = LOCALHOST ? 'http' : 'https';

  const paths = {
    root: isEventsSite ? '/events-booking' : '/class-booking',
    courses: isCourseSite ? '/class-booking' : `https://${siteHost}`,
    events: isEventsSite ? '/events-booking' : `https://${EVENTS_HOST}`,
    adultCourses: `${siteProtocol}://${ADULTS_HOST}/class-booking`,
    childrenCourses: `${siteProtocol}://${CHILD_HOST}/class-booking`,
  };

  const navProps = useMemo(() => ({
    paths,
    isCourseSite,
    isEventsSite,
    isChildSite,
    isAdultSite,
  }), []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavigationContext.Provider value={navProps}>
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationProvider, NavigationContext };
