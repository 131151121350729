// theme.js
import { extendTheme } from '@chakra-ui/react';

import Button from './components/button';

const hexFromRGBA = (red:number, green:number, blue:number, alpha:number): string => {
  const base = 255; // white

  function convertChannelToHex(val : number) {
    return val * alpha + base * (1 - alpha);
  }

  const r = convertChannelToHex(red);
  const g = convertChannelToHex(green);
  const b = convertChannelToHex(blue);
  return `rgb(${r},${g},${b})`;
};

const boxShadow = '6px 6px 19px 3px rgb(0 0 0 / 17%)';

const theme = extendTheme({
  colors: {
    blue: {
      900: '#2d2c7a',
      800: hexFromRGBA(45, 44, 122, 0.8),
      700: hexFromRGBA(45, 44, 122, 0.9),
      600: hexFromRGBA(45, 44, 122, 0.8),
      500: hexFromRGBA(45, 44, 122, 0.7),
      400: hexFromRGBA(45, 44, 122, 0.6),
      300: hexFromRGBA(45, 44, 122, 0.5),
      200: hexFromRGBA(45, 44, 122, 0.4),
      100: hexFromRGBA(45, 44, 122, 0.3),
      50: hexFromRGBA(45, 44, 122, 0.1),
    },
    pink: {
      900: '#ff6f7d',
      800: hexFromRGBA(255, 111, 125, 1),
      700: hexFromRGBA(255, 111, 125, 0.9),
      600: hexFromRGBA(255, 111, 125, 0.8),
      500: hexFromRGBA(255, 111, 125, 0.7),
      400: hexFromRGBA(255, 111, 125, 0.6),
      300: hexFromRGBA(255, 111, 125, 0.5),
      200: hexFromRGBA(255, 111, 125, 0.4),
      100: hexFromRGBA(255, 111, 125, 0.3),
      50: hexFromRGBA(255, 111, 125, 0.1),
    },
    yellow: {
      900: '#fcc33e',
      800: hexFromRGBA(252, 195, 62, 1),
      700: hexFromRGBA(252, 195, 62, 0.9),
      600: hexFromRGBA(252, 195, 62, 0.8),
      500: hexFromRGBA(252, 195, 62, 0.7),
      400: hexFromRGBA(252, 195, 62, 0.6),
      300: hexFromRGBA(252, 195, 62, 0.5),
      200: hexFromRGBA(252, 195, 62, 0.4),
      100: hexFromRGBA(252, 195, 62, 0.3),
      50: hexFromRGBA(252, 195, 62, 0.1),
    },
    aqua: {
      900: '#5ee2ea',
      800: hexFromRGBA(94, 226, 234, 1),
      700: hexFromRGBA(94, 226, 234, 0.9),
      600: hexFromRGBA(94, 226, 234, 0.8),
      500: hexFromRGBA(94, 226, 234, 0.7),
      400: hexFromRGBA(94, 226, 234, 0.6),
      300: hexFromRGBA(94, 226, 234, 0.5),
      200: hexFromRGBA(94, 226, 234, 0.4),
      100: hexFromRGBA(94, 226, 234, 0.3),
      50: hexFromRGBA(94, 226, 234, 0.1),
    },
    violet: {
      900: '#beafe2',
      800: hexFromRGBA(190, 175, 226, 1),
      700: hexFromRGBA(190, 175, 226, 0.9),
      600: hexFromRGBA(190, 175, 226, 0.8),
      500: hexFromRGBA(190, 175, 226, 0.7),
      400: hexFromRGBA(190, 175, 226, 0.6),
      300: hexFromRGBA(190, 175, 226, 0.5),
      200: hexFromRGBA(190, 175, 226, 0.4),
      100: hexFromRGBA(190, 175, 226, 0.3),
      50: hexFromRGBA(190, 175, 226, 0.1),
    },
  },
  fonts: {
    heading: 'Larken',
    body: 'Hando',
  },
  textStyles: {
    emphasise: {
      fontFamily: 'Hando',
      fontWeight: '600',
    },
  },
  components: {
    Button,

    Badge: {
      sizes: {
        sm: {
          paddingTop: '1px',
        },
        md: {
          paddingTop: '2px',
          fontSize: 'md',
        },
        lg: {
          fontSize: 'lg',
        },
      },
    },
    Stat: {
      baseStyle: {
        number: {
          fontSize: 'md',
          color: 'blue.900',
        },
        label: {
          color: 'blue.900',
        },
      },
      sizes: {
        md: {
          number: 'md',
        },
      },
      variants: {
        centered: {
          number: { textAlign: 'center' },
          label: { textAlign: 'center' },
        },
      },
    },
    Heading: {
      baseStyle: {
        color: 'blue.900',
      },
      sizes: {
        lg: {
          fontFamily: 'Hando',
          marginTop: 'var(--chakra-space-1)',
          marginBottom: 'var(--chakra-space-1)',
        },
        md: {
          fontFamily: 'Hando',
          marginTop: 'var(--chakra-space-1)',
          marginBottom: 'var(--chakra-space-1)',
        },
      },
    },
    Nav: {
      baseStyle: {
        boxShadow,
      },
    },
    StripeElement: {
      baseStyle: {
        zIndex: 1,
        height: '2.5rem',
        borderRadius: '0.375rem',
        border: '1px solid rgb(226, 232, 240)',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        paddingTop: '0.7rem',
        transition: '0.2s all',
      },
      variants: {
        focused: {
          borderColor: 'rgb(108.00000000000001,107.30000000000001,161.9)',
          boxShadow: '0 0 0 1px rgb(108 107 162)',
        },
      },
    },
    CourseBox: {
      parts: [
        'CourseInfo',
        'CourseImage',
        'CourseDescription',
      ],
      baseStyle: {
        borderWidth: '3px',
        bgColor: 'white',
      },
      variants: {
        StudioCourse: {
          borderColor: 'aqua.900',
          bgColor: 'aqua.50',
        },
        OnlineCourse: {
          borderColor: 'violet.900',
          bgColor: 'violet.50',
        },
      },
    },
    CourseContent: {
      parts: [
        'CourseContainer',
        'CourseInfo',
        'CourseImage',
        'CourseDescription',
        'CourseOriginalPrice',
      ],
      baseStyle: {
        CourseContainer: {
          CourseContainer: {
            flexDirection: 'row',
          },
        },
        CourseOriginalPrice: {
          textDecoration: 'line-through',
          fontStyle: 'oblique',
          color: 'red.500',
          fontWeight: 'normal',
        },
      },
      variants: {
        Horizontal: {
          CourseContainer: {
            flexDirection: 'row',
          },
        },
        Vertical: {
          CourseContainer: {
            flexDirection: 'column',
          },
          CourseImage: {
            width: '100% !important',
            height: '400px !important',
          },
        },
      },
    },
    CourseBoxTitle: {
      baseStyle: {
        padding: 'var(--chakra-space-3)',
      },
      variants: {
        StudioCourse: {
          bgColor: 'aqua.900',
          // bgColor: 'aqua.50',
        },
        OnlineCourse: {
          bgColor: 'violet.900',
          // bgColor: 'violet.50',
        },
      },
    },
    ContentBox: {
      baseStyle: {
        borderRadius: '8px',
        padding: '1rem',
        boxShadow,
        // backgroundColor: '#fff',
        // border: '1px solid',
        // borderColor: 'gray.100',
        width: '100%',
      },
      variants: {
        small: {
          paddingY: '1',
          paddingX: '2',
        },
        pinkBorder: {
          border: '2px solid',
          borderColor: 'pink.400',
        },
      },
    },
    ItemBox: {
      baseStyle: {
        backgroundColor: '#fffffff',
        padding: 6,
      },
    },
    FadedBackground: {
      baseStyle: {
        background: 'rgba(250,250,250,0.5)',
      },
    },
    Breadcrumb: {
      baseStyle: {
        link: {
          color: 'blue.900',
          textTransform: 'capitalize',
        },
        color: 'blue.900',
      },
    },
  },
});
export default theme;
