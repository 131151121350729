/* eslint-disable react/destructuring-assignment */
import React, {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';

export type ContextType = {
  loaderActive: boolean
  loaderText: string
  showLoader: (text?: string) => void
  hideLoader: () => void
  updateLoaderText: (text: string) => void;
}

type ILoaderProviderProps = {
  children: ReactNode
}

export const LoaderContext = createContext<ContextType>(undefined);

const LoaderProvider = ({ children }: ILoaderProviderProps): any => {
  const textDefault = 'Loading...';
  const [loaderActive, setLoaderActive] = useState(false);
  const [loaderText, setLoaderText] = useState(textDefault);

  const showLoader = (text?: string): void => {
    if (text) {
      setLoaderText(text);
    } else {
      setLoaderText(textDefault);
    }
    setLoaderActive(true);
  };

  const hideLoader = (): void => {
    setLoaderActive(false);
    setLoaderText(textDefault);
  };

  const updateLoaderText = (text: string): void => {
    setLoaderText(text);
  };

  const contextProps = useMemo(() => ({
    loaderActive, loaderText, showLoader, hideLoader, updateLoaderText,
  }), []);

  return (
    <LoaderContext.Provider value={contextProps}>
      {children}
    </LoaderContext.Provider>
  );
};

type LoginProviderType = {
  loaderActive
  loaderText
  showLoader: (text?: string) => void,
  hideLoader: () => void,
  updateLoaderText: (text: string) => void,
}

function useLoader(): LoginProviderType {
  return useContext(LoaderContext);
}

export { LoaderProvider, useLoader };
