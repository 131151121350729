import {
  Button, FormControl, FormErrorMessage, Icon, Input,
  InputGroup, InputRightElement, useToast, Text, Select,
} from '@chakra-ui/react';
import React, {
  useContext,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {
  useRegisterMutation, MeDocument, useLoginMutation, IHeardAboutUs,
} from '../../lib/graphql';
import { NavigationContext } from '../../lib/contexts/NavigationContext';

interface IFormProps {
  onSuccess: () => void;
  onError: (error: IAlerterItem) => void;
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup.string().email().required('Please enter a valid email'),
  password: yup
    .string()
    .min(6, 'Please use more than 6 characters')
    .required()
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, { message: 'Please use: One uppercase character and one number.' }),
  weChatId: yup.string(),
  phone: yup.string().required('Phone/Whatsapp number required').matches(phoneRegExp, 'Phone number not valid'),
}, []);

const RegisterForm = ({ onSuccess, onError }: IFormProps): JSX.Element => {
  const { isChildSite } = useContext(NavigationContext);
  const inputPrefix = isChildSite ? 'Parent ' : '';

  const {
    handleSubmit, errors, register, formState,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [registerMutation] = useRegisterMutation();
  const [loginMutation] = useLoginMutation();
  const toast = useToast();

  async function onSubmit(values: Record<string, string>) {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      weChatId: values.weChatId,
      phone: values.phone,
      heardAboutUs: null,
    };

    if (values?.heardAboutUs?.length > 0) data.heardAboutUs = values.heardAboutUs;

    registerMutation({
      variables: {
        data,
      },
    }).then((resp) => {
      if (resp.data.register.user) {
        toast({
          title: "You're registered!",
          description: "We've logged you in just remember your password.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        loginMutation({
          variables: {
            data: {
              email: data.email,
              password: data.password,
            },
          },
          refetchQueries: [{ query: MeDocument }],
        });
        onSuccess();
      } else {
        const { errors: graphErrors } = resp.data.register;
        const errorMessage = graphErrors.reduce((arr, error) => arr.concat(`${error.message} `), '');
        const message = graphErrors.length > 0 ? errorMessage : 'Please try again.';
        onError({
          message,
          autoDismiss: true,
          type: 'error',
          key: 'register',
        });
      }
    });
  }

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={errors.firstName}>
        <Input
          name="firstName"
          placeholder={`${inputPrefix}First name`}
          aria-label="First name"
          autoComplete="login firstName"
          type="firstName"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.firstName && errors.firstName.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.lastName} mt={2}>
        <Input
          name="lastName"
          placeholder={`${inputPrefix}Last name`}
          aria-label="Last name"
          autoComplete="login lastName"
          type="lastName"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.lastName && errors.lastName.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.email} mt={2}>
        <Input
          name="email"
          placeholder="Email"
          aria-label="Email address"
          autoComplete="login email"
          type="email"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.password} mt={2}>
        <InputGroup size="md">
          <Input
            name="password"
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            aria-label="Password"
            ref={register}
            autoComplete="new-password"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick} variant="outline" colorScheme="pink">
              {show ? (<Icon as={FiEye} />) : (<Icon as={FiEyeOff} />)}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.weChatId} mt={2}>
        {/* <Text>Where did you hear about us?</Text> */}
        <Select placeholder="Where did you hear about us?" aria-label="Where did you hear about us?" name="heardAboutUs" ref={register()}>
          {
            Object.keys(IHeardAboutUs).map(
              (key) => <option value={key.toUpperCase()}>{key}</option>,
            )
          }
        </Select>
        <FormErrorMessage>
          {errors.weChatId && errors.weChatId.message}
        </FormErrorMessage>
      </FormControl>

      {/* <Container border="1px solid" borderColor="gray.100" py={4} mt={2}> */}
      <FormControl isInvalid={errors.phone} mt={2}>
        <Input
          name="phone"
          placeholder="Phone/Whatsapp number"
          aria-label="Phone number"
          autoComplete="login phone"
          type="phone"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.phone && errors.phone.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.weChatId} mt={2}>
        <Input
          name="weChatId"
          placeholder="WeChat ID - To join the group chat"
          aria-label="WeChat ID"
          type="weChatId"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.weChatId && errors.weChatId.message}
        </FormErrorMessage>
      </FormControl>

      <Text fontSize="sm" mt={2} textAlign="center">
        Enter your WeChat ID or/and WhatsApp number to join the course group chat
      </Text>

      <Button
        mt={4}
        colorScheme="yellow"
        isLoading={formState.isSubmitting}
        type="submit"
      >
        Sign up
      </Button>
    </form>
  );
};

export default RegisterForm;
