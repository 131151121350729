import { Button, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useAuth } from '../../pages/api/userAuth';
import LoginModal from './LoginModal';

interface ButtonProps {
  variant?: string
}

const LoginButton = ({ variant = 'solid' }:ButtonProps) => {
  const { user, logout } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openLogin = () => {
    onClose();
    onOpen();
  };

  return (
    <>
      { user
        ? <Button variant={variant} size="sm" onClick={() => logout()}>Log Out</Button>
        : <Button variant={variant} size="sm" onClick={() => openLogin()}>Log In</Button>}
      <LoginModal onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default LoginButton;
