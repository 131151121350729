import router from 'next/router';
import React from 'react';
import { Button } from '@chakra-ui/react';
import NavMenu from '../navMenu';
import NavMenuItem from '../NavMenuItem';
import { useAuth } from '../../../pages/api/userAuth';

type MyAccountNavProps = {
  variant?: 'menu' | 'buttons'
}
const MyAccountNav = ({ variant = 'menu' }: MyAccountNavProps) => {
  const { user } = useAuth();
  const { pathname } = router;
  const isActive = pathname.indexOf('account') > -1;

  return (variant === 'menu' ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user ? (
        <NavMenu title="My Account" isActive={isActive}>
          <NavMenuItem label="Summary" onClick={(() => { router.push('/my-account'); })} />
          <NavMenuItem label="My Orders" onClick={(() => { router.push('/my-account/orders'); })} />
          <NavMenuItem label="My Courses" onClick={(() => { router.push('/my-account/courses'); })} />
          {/* <NavMenuItem */}
          {/*  label="My Events" */}
          {/*  onClick={( */}
          {/*    () => { router.push('/my-account/events'); })} */}
          {/* /> */}
        </NavMenu>
      ) : null}
    </>
  ) : (
    <>
      <Button variant="link" onClick={() => router.push('/my-account')}>Summary</Button>
      <Button variant="link" onClick={() => router.push('/my-account/orders')}>My Orders</Button>
      <Button variant="link" onClick={() => router.push('/my-account/courses')}>My Courses</Button>
      {/* <Button onClick={() => router.push('/my-account/events')}>My Events</Button> */}
    </>
  ));
};

export default MyAccountNav;
