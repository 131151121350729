import React from 'react';
import { Link } from '@chakra-ui/react';
import { IRole } from '../../lib/graphql';
import { useAuth } from '../../pages/api/userAuth';

const PreviewNav = () => {
  const { user } = useAuth();

  return user?.roles.includes(IRole.Admin) ? <Link href="?preview=PREVIEW">Preview</Link> : null;
};

export default PreviewNav;
