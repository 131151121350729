import {
  Menu, MenuButton, MenuList, useStyleConfig, Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { ReactNode } from 'react';

interface IMenuProps {
  title: string
  children: ReactNode
  isActive?: boolean
}

const NavMenu = ({ title, children, isActive = false }: IMenuProps) => {
  const navStyle = useStyleConfig('Button', { variant: 'navigation', colorScheme: 'blue', size: 'sm' });
  const navActiveStyle = useStyleConfig('Button', { variant: 'navigationActive', colorScheme: 'blue', size: 'sm' });

  return (
    <Menu>
      <MenuButton
        as={Button}
        sx={isActive ? navActiveStyle : navStyle}
        rightIcon={<ChevronDownIcon pb="2px" ml="-6px" />}
      >
        {title}
      </MenuButton>
      <MenuList sx={{ p: 0 }}>
        { children }
      </MenuList>
    </Menu>
  );
};

export default NavMenu;
