import { Button } from '@chakra-ui/react';
import React, { useContext } from 'react';
import router from 'next/router';
import NavMenu from '../navMenu';
import NavMenuItem from '../NavMenuItem';
import { NavigationContext } from '../../../lib/contexts/NavigationContext';

type NavMenuItemProps = {
  variant?: 'menu' | 'buttons'
}
const ClassBookingNav = ({ variant = 'menu' }: NavMenuItemProps) => {
  const { paths } = useContext(NavigationContext);
  const { pathname } = router;
  const isActive = pathname.indexOf('class-booking') > -1;

  return variant === 'menu' ? (
    <NavMenu title="Class Booking" isActive={isActive}>
      <NavMenuItem label="Adult" url={paths.adultCourses} />
      <NavMenuItem label="Children" url={paths.childrenCourses} />
      <NavMenuItem label="Private Class" url="https://ukchinaperformingarts.com/dance-school-london/private-classes" />
    </NavMenu>
  ) : (
    <>
      <Button variant="link" onClick={() => router.push(paths.adultCourses)}>Adult</Button>
      <Button variant="link" onClick={() => router.push(paths.childrenCourses)}>Children</Button>
      <Button variant="link" onClick={() => router.push('https://ukchinaperformingarts.com/dance-school-london/private-classes')}>Private Class</Button>
    </>
  );
};

export default ClassBookingNav;
