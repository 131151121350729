import {
  Flex, Box, Image, Heading, useStyleConfig,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useLoader } from '../../lib/contexts/LoaderContext';
import styles from './styles.module.css';

const Loader = ():ReactElement => {
  const { loaderActive, loaderText } = useLoader();
  const boxStyles = useStyleConfig('FadedBackground');

  return (
    loaderActive && (
      <Flex
        position="fixed"
        top="0"
        left="0"
        h="100vh"
        w="100vw"
        alignItems="center"
        justifyItems="center"
        sx={boxStyles}
      >
        <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center" width="100%">
          <Box w="150px" h="150px" border="none">
            <Box className={styles.loader} alignItems="center" justifyItems="center" display="flex">
              <Image src="/images/UKCPA-Logo-Mark-01.png" h="60%" w="60%" margin="auto" />
            </Box>
          </Box>
          <Box flex="0 0 0" mt={8}>
            <Heading as="h5">{loaderText}</Heading>
          </Box>
        </Box>
      </Flex>
    )
  );
};

export default Loader;
