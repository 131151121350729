import { Image } from '@chakra-ui/react';
import isMobile from '../../utils/isMobile';

interface logoProps {
  defaultSize?: number
  mobileSize?: number
}

const logo = ({ defaultSize = 200, mobileSize = 50 } : logoProps) => (isMobile()
  ? <Image w={mobileSize} src="/images/UKCPA-Logo-Mark-01.png" />
  : <Image w={defaultSize} src="/images/UKCPA-logo-01.png" />);

export default logo;
