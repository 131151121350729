import { Button } from '@chakra-ui/react';

interface Props {
  label: string
  action: () => Promise<boolean>
}

const NavLink = ({ label, action }: Props) => (
  <Button
    px={2}
    py={1}
    onClick={action}
    size="sm"
    variant="ghostHover"
    colorScheme="blue"
  >
    {label}
  </Button>
);

export default NavLink;
